export const states = [
   { id: '', name: 'Estado', disabled: true },
   { id: 'AC', name: 'Acre' },
   { id: 'AL', name: 'Alagoas' },
   { id: 'AP', name: 'Amapá' },
   { id: 'AM', name: 'Amazonas' },
   { id: 'BA', name: 'Bahia' },
   { id: 'CE', name: 'Ceará' },
   { id: 'DF', name: 'Distrito Federal' },
   { id: 'ES', name: 'Espírito Santo' },
   { id: 'GO', name: 'Goiás' },
   { id: 'MA', name: 'Maranhão' },
   { id: 'MT', name: 'Mato Grosso' },
   { id: 'MS', name: 'Mato Grosso do Sul' },
   { id: 'MG', name: 'Minas Gerais' },
   { id: 'PA', name: 'Pará' },
   { id: 'PB', name: 'Paraíba' },
   { id: 'PR', name: 'Paraná' },
   { id: 'PE', name: 'Pernambuco' },
   { id: 'PI', name: 'Piauí' },
   { id: 'RJ', name: 'Rio de Janeiro' },
   { id: 'RN', name: 'Rio Grande do Norte' },
   { id: 'RS', name: 'Rio Grande do Sul' },
   { id: 'RO', name: 'Rondônia' },
   { id: 'RR', name: 'Roraima' },
   { id: 'SC', name: 'Santa Catarina' },
   { id: 'SP', name: 'São Paulo' },
   { id: 'SE', name: 'Sergipe' },
   { id: 'TO', name: 'Tocantins' },
];

export const accountType = [
   {
      id: 'checking',
      name: 'Conta Corrente',
   },
   {
      id: 'savings',
      name: 'Poupança',
   },
];

export const bankCodes = [
   {
      id: '001',
      name: 'Banco do Brasil S.A.',
   },
   {
      id: '003',
      name: 'Banco da Amazônia S.A.',
   },
   {
      id: '004',
      name: 'Banco do Nordeste do Brasil S.A.',
   },
   {
      id: '007',
      name: 'Banco Nacional de Desenvolvimento Econômico e Social – BNDES',
   },
   {
      id: '010',
      name: 'CREDICOAMO CREDITO RURAL COOPERATIVA',
   },
   {
      id: '011',
      name: 'CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A',
   },
   {
      id: '012',
      name: 'Banco Inbursa S.A.',
   },
   {
      id: '014',
      name: 'State Street Brasil S.A. – Banco Comercial',
   },
   {
      id: '015',
      name: 'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
   },
   {
      id: '016',
      name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
   },
   {
      id: '017',
      name: 'BNY Mellon Banco S.A.',
   },
   {
      id: '018',
      name: 'Banco Tricury S.A.',
   },
   {
      id: '021',
      name: 'BANESTES S.A. Banco do Estado do Espírito Santo',
   },
   {
      id: '024',
      name: 'Banco BANDEPE S.A.',
   },
   {
      id: '025',
      name: 'Banco Alfa S.A.',
   },
   {
      id: '029',
      name: 'Banco Itaú Consignado S.A.',
   },
   {
      id: '033',
      name: 'Banco Santander (Brasil) S.A.',
   },
   {
      id: '036',
      name: 'Banco Bradesco BBI S.A.',
   },
   {
      id: '037',
      name: 'Banco do Estado do Pará S.A.',
   },
   {
      id: '040',
      name: 'Banco Cargill S.A.',
   },
   {
      id: '041',
      name: 'Banco do Estado do Rio Grande do Sul S.A.',
   },
   {
      id: '047',
      name: 'Banco do Estado de Sergipe S.A.',
   },
   {
      id: '060',
      name: 'Confidence Corretora de Câmbio S.A.',
   },
   {
      id: '062',
      name: 'Hipercard Banco Múltiplo S.A.',
   },
   {
      id: '063',
      name: 'Banco Bradescard S.A.',
   },
   {
      id: '064',
      name: 'Goldman Sachs do Brasil Banco Múltiplo S.A.',
   },
   {
      id: '065',
      name: 'Banco Andbank (Brasil) S.A.',
   },
   {
      id: '066',
      name: 'Banco Morgan Stanley S.A.',
   },
   {
      id: '069',
      name: 'Banco Crefisa S.A.',
   },
   {
      id: '070',
      name: 'BRB – Banco de Brasília S.A.',
   },
   {
      id: '074',
      name: 'Banco J. Safra S.A.',
   },
   {
      id: '075',
      name: 'Banco ABN AMRO S.A.',
   },
   {
      id: '076',
      name: 'Banco KDB S.A.',
   },
   {
      id: '077',
      name: 'Banco Inter S.A.',
   },
   {
      id: '078',
      name: 'Haitong Banco de Investimento do Brasil S.A.',
   },
   {
      id: '079',
      name: 'Banco Original do Agronegócio S.A.',
   },
   {
      id: '080',
      name: 'B&T CORRETORA DE CAMBIO LTDA.',
   },
   {
      id: '081',
      name: 'BancoSeguro S.A.',
   },
   {
      id: '082',
      name: 'Banco Topázio S.A.',
   },
   {
      id: '083',
      name: 'Banco da China Brasil S.A.',
   },
   {
      id: '084',
      name: 'Uniprime Norte do Paraná – Coop de Economia e Crédito Mútuo dos Médicos',
   },
   {
      id: '085',
      name: 'Cooperativa Central de Crédito – AILOS',
   },
   {
      id: '089',
      name: 'CREDISAN COOPERATIVA DE CRÉDITO',
   },
   {
      id: '091',
      name: 'CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S',
   },
   {
      id: '092',
      name: 'Brickell S.A. Crédito',
   },
   {
      id: '093',
      name: 'PÓLOCRED SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT',
   },
   {
      id: '094',
      name: 'Banco Finaxis S.A.',
   },
   {
      id: '095',
      name: 'Travelex Banco de Câmbio S.A.',
   },
   {
      id: '096',
      name: 'Banco B3 S.A.',
   },
   {
      id: '097',
      name: 'Cooperativa Central de Crédito Noroeste Brasileiro Ltda.',
   },
   {
      id: '098',
      name: 'Credialiança Cooperativa de Crédito Rural',
   },
   {
      id: '099',
      name: 'UNIPRIME CENTRAL – CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.',
   },
   {
      id: '100',
      name: 'Planner Corretora de Valores S.A.',
   },
   {
      id: '101',
      name: 'RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
   },
   {
      id: '102',
      name: 'XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A',
   },
   {
      id: '104',
      name: 'Caixa Econômica Federal',
   },
   {
      id: '105',
      name: 'Lecca Crédito, Financiamento e Investimento S/A',
   },
   {
      id: '107',
      name: 'Banco BOCOM BBM S.A.',
   },
   {
      id: '108',
      name: 'PORTOCRED S.A. – CREDITO, FINANCIAMENTO E INVESTIMENTO',
   },
   {
      id: '111',
      name: 'OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.',
   },
   {
      id: '113',
      name: 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios',
   },
   {
      id: '114',
      name: 'Central Cooperativa de Crédito no Estado do Espírito Santo – CECOOP',
   },
   {
      id: '117',
      name: 'ADVANCED CORRETORA DE CÂMBIO LTDA',
   },
   {
      id: '119',
      name: 'Banco Western Union do Brasil S.A.',
   },
   {
      id: '120',
      name: 'Banco Rodobens S.A.',
   },
   {
      id: '121',
      name: 'Banco Agibank S.A.',
   },
   {
      id: '122',
      name: 'Banco Bradesco BERJ S.A.',
   },
   {
      id: '124',
      name: 'Banco Woori Bank do Brasil S.A.',
   },
   {
      id: '125',
      name: 'Plural S.A. – Banco Múltiplo',
   },
   {
      id: '126',
      name: 'BR Partners Banco de Investimento S.A.',
   },
   {
      id: '127',
      name: 'Codepe Corretora de Valores e Câmbio S.A.',
   },
   {
      id: '128',
      name: 'MS Bank S.A. Banco de Câmbio',
   },
   {
      id: '129',
      name: 'UBS Brasil Banco de Investimento S.A.',
   },
   {
      id: '130',
      name: 'CARUANA S.A. – SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
   },
   {
      id: '131',
      name: 'TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA',
   },
   {
      id: '132',
      name: 'ICBC do Brasil Banco Múltiplo S.A.',
   },
   {
      id: '133',
      name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E',
   },
   {
      id: '134',
      name: 'BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
   },
   {
      id: '136',
      name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. – UNICRED DO BRASI',
   },
   {
      id: '138',
      name: 'Get Money Corretora de Câmbio S.A.',
   },
   {
      id: '139',
      name: 'Intesa Sanpaolo Brasil S.A. – Banco Múltiplo',
   },
   {
      id: '140',
      name: 'Easynvest – Título Corretora de Valores SA',
   },
   {
      id: '142',
      name: 'Broker Brasil Corretora de Câmbio Ltda.',
   },
   {
      id: '143',
      name: 'Treviso Corretora de Câmbio S.A.',
   },
   {
      id: '144',
      name: 'BEXS Banco de Câmbio S.A.',
   },
   {
      id: '145',
      name: 'LEVYCAM – CORRETORA DE CAMBIO E VALORES LTDA.',
   },
   {
      id: '146',
      name: 'GUITTA CORRETORA DE CAMBIO LTDA.',
   },
   {
      id: '149',
      name: 'Facta Financeira S.A. – Crédito Financiamento e Investimento',
   },
   {
      id: '157',
      name: 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
   },
   {
      id: '159',
      name: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
   },
   {
      id: '163',
      name: 'Commerzbank Brasil S.A. – Banco Múltiplo',
   },
   {
      id: '169',
      name: 'Banco Olé Bonsucesso Consignado S.A.',
   },
   {
      id: '173',
      name: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
   },
   {
      id: '174',
      name: 'PERNAMBUCANAS FINANCIADORA S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
   },
   {
      id: '177',
      name: 'Guide Investimentos S.A. Corretora de Valores',
   },
   {
      id: '180',
      name: 'CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA',
   },
   {
      id: '183',
      name: 'SOCRED S.A. – SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P',
   },
   {
      id: '184',
      name: 'Banco Itaú BBA S.A.',
   },
   {
      id: '188',
      name: 'ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES',
   },
   {
      id: '189',
      name: 'HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS',
   },
   {
      id: '190',
      name: 'SERVICOOP – COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN',
   },
   {
      id: '191',
      name: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
   },
   {
      id: '194',
      name: 'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
   },
   {
      id: '196',
      name: 'FAIR CORRETORA DE CAMBIO S.A.',
   },
   {
      id: '197',
      name: 'Stone Pagamentos S.A.',
   },
   {
      id: '208',
      name: 'Banco BTG Pactual S.A.',
   },
   {
      id: '212',
      name: 'Banco Original S.A.',
   },
   {
      id: '213',
      name: 'Banco Arbi S.A.',
   },
   {
      id: '217',
      name: 'Banco John Deere S.A.',
   },
   {
      id: '218',
      name: 'Banco BS2 S.A.',
   },
   {
      id: '222',
      name: 'Banco Credit Agricole Brasil S.A.',
   },
   {
      id: '224',
      name: 'Banco Fibra S.A.',
   },
   {
      id: '233',
      name: 'Banco Cifra S.A.',
   },
   {
      id: '237',
      name: 'Next',
   },
   {
      id: '237',
      name: 'Banco Bradesco S.A.',
   },
   {
      id: '241',
      name: 'Banco Clássico S.A.',
   },
   {
      id: '243',
      name: 'Banco Máxima S.A.',
   },
   {
      id: '246',
      name: 'Banco ABC Brasil S.A.',
   },
   {
      id: '249',
      name: 'Banco Investcred Unibanco S.A.',
   },
   {
      id: '250',
      name: 'BCV – Banco de Crédito e Varejo S.A.',
   },
   {
      id: '253',
      name: 'Bexs Corretora de Câmbio S/A',
   },
   {
      id: '254',
      name: 'Paraná Banco S.A.',
   },
   {
      id: '259',
      name: 'MONEYCORP BANCO DE CÂMBIO S.A.',
   },
   {
      id: '260',
      name: 'NU Pagamentos S.A. – Nubank',
   },
   {
      id: '265',
      name: 'Banco Fator S.A.',
   },
   {
      id: '266',
      name: 'Banco Cédula S.A.',
   },
   {
      id: '268',
      name: 'BARI COMPANHIA HIPOTECÁRIA',
   },
   {
      id: '269',
      name: 'HSBC Brasil S.A. – Banco de Investimento',
   },
   {
      id: '270',
      name: 'Sagitur Corretora de Câmbio Ltda.',
   },
   {
      id: '271',
      name: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
   },
   {
      id: '272',
      name: 'AGK CORRETORA DE CAMBIO S.A.',
   },
   {
      id: '273',
      name: 'Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel',
   },
   {
      id: '274',
      name: 'MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT',
   },
   {
      id: '276',
      name: 'Senff S.A. – Crédito, Financiamento e Investimento',
   },
   {
      id: '278',
      name: 'Genial Investimentos Corretora de Valores Mobiliários S.A.',
   },
   {
      id: '279',
      name: 'COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE',
   },
   {
      id: '280',
      name: 'Avista S.A. Crédito, Financiamento e Investimento',
   },
   {
      id: '281',
      name: 'Cooperativa de Crédito Rural Coopavel',
   },
   {
      id: '283',
      name: 'RB CAPITAL INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LIMITADA',
   },
   {
      id: '285',
      name: 'Frente Corretora de Câmbio Ltda.',
   },
   {
      id: '286',
      name: 'COOPERATIVA DE CRÉDITO RURAL DE OURO SULCREDI/OURO',
   },
   {
      id: '288',
      name: 'CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
   },
   {
      id: '289',
      name: 'DECYSEO CORRETORA DE CAMBIO LTDA.',
   },
   {
      id: '290',
      name: 'Pagseguro Internet S.A. – PagBank',
   },
   {
      id: '292',
      name: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.',
   },
   {
      id: '293',
      name: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.',
   },
   {
      id: '296',
      name: 'VISION S.A. CORRETORA DE CAMBIO',
   },
   {
      id: '298',
      name: 'Vip’s Corretora de Câmbio Ltda.',
   },
   {
      id: '299',
      name: 'SOROCRED CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
   },
   {
      id: '300',
      name: 'Banco de La Nacion Argentina',
   },
   {
      id: '301',
      name: 'BPP Instituição de Pagamento S.A.',
   },
   {
      id: '306',
      name: 'PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
   },
   {
      id: '307',
      name: 'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
   },
   {
      id: '309',
      name: 'CAMBIONET CORRETORA DE CÂMBIO LTDA.',
   },
   {
      id: '310',
      name: 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
   },
   {
      id: '315',
      name: 'PI Distribuidora de Títulos e Valores Mobiliários S.A.',
   },
   {
      id: '318',
      name: 'Banco BMG S.A.',
   },
   {
      id: '319',
      name: 'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
   },
   {
      id: '320',
      name: 'China Construction Bank (Brasil) Banco Múltiplo S.A.',
   },
   {
      id: '321',
      name: 'CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT',
   },
   {
      id: '322',
      name: 'Cooperativa de Crédito Rural de Abelardo Luz – Sulcredi/Crediluz',
   },
   {
      id: '323',
      name: 'MERCADOPAGO.COM REPRESENTACOES LTDA.',
   },
   {
      id: '325',
      name: 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.',
   },
   {
      id: '326',
      name: 'PARATI – CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
   },
   {
      id: '329',
      name: 'QI Sociedade de Crédito Direto S.A.',
   },
   {
      id: '330',
      name: 'Banco Bari de Investimentos e Financiamentos S/A',
   },
   {
      id: '331',
      name: 'Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.',
   },
   {
      id: '332',
      name: 'Acesso Soluções de Pagamento S.A.',
   },
   {
      id: '335',
      name: 'Banco Digio S.A.',
   },
   {
      id: '336',
      name: 'Banco C6 S.A – C6 Bank',
   },
   {
      id: '340',
      name: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.',
   },
   {
      id: '341',
      name: 'Itaú Unibanco S.A.',
   },
   {
      id: '342',
      name: 'Creditas Sociedade de Crédito Direto S.A.',
   },
   {
      id: '343',
      name: 'FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
   },
   {
      id: '348',
      name: 'Banco XP S.A.',
   },
   {
      id: '349',
      name: 'AMAGGI S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
   },
   {
      id: '352',
      name: 'TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
   },
   {
      id: '354',
      name: 'NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES',
   },
   {
      id: '355',
      name: 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
   },
   {
      id: '364',
      name: 'GERENCIANET PAGAMENTOS DO BRASIL LTDA',
   },
   {
      id: '365',
      name: 'SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS',
   },
   {
      id: '366',
      name: 'Banco Société Générale Brasil S.A.',
   },
   {
      id: '367',
      name: 'VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
   },
   {
      id: '368',
      name: 'Banco Carrefour',
   },
   {
      id: '370',
      name: 'Banco Mizuho do Brasil S.A.',
   },
   {
      id: '373',
      name: 'UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
   },
   {
      id: '376',
      name: 'Banco J. P. Morgan S.A.',
   },
   {
      id: '380',
      name: 'PicPay',
   },
   {
      id: '389',
      name: 'Banco Mercantil do Brasil S.A.',
   },
   {
      id: '394',
      name: 'Banco Bradesco Financiamentos S.A.',
   },
   {
      id: '399',
      name: 'Kirton Bank S.A. – Banco Múltiplo',
   },
   {
      id: '403',
      name: 'Cora',
   },
   {
      id: '412',
      name: 'Banco Capital S.A.',
   },
   {
      id: '422',
      name: 'Banco Safra S.A.',
   },
   {
      id: '456',
      name: 'Banco MUFG Brasil S.A.',
   },
   {
      id: '464',
      name: 'Banco Sumitomo Mitsui Brasileiro S.A.',
   },
   {
      id: '473',
      name: 'Banco Caixa Geral – Brasil S.A.',
   },
   {
      id: '477',
      name: 'Citibank N.A.',
   },
   {
      id: '479',
      name: 'Banco ItauBank S.A',
   },
   {
      id: '487',
      name: 'Deutsche Bank S.A. – Banco Alemão',
   },
   {
      id: '488',
      name: 'JPMorgan Chase Bank',
   },
   {
      id: '492',
      name: 'ING Bank N.V.',
   },
   {
      id: '495',
      name: 'Banco de La Provincia de Buenos Aires',
   },
   {
      id: '505',
      name: 'Banco Credit Suisse (Brasil) S.A.',
   },
   {
      id: '545',
      name: 'SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A',
   },
   {
      id: '600',
      name: 'Banco Luso Brasileiro S.A.',
   },
   {
      id: '604',
      name: 'Banco Industrial do Brasil S.A.',
   },
   {
      id: '610',
      name: 'Banco VR S.A.',
   },
   {
      id: '611',
      name: 'Banco Paulista S.A.',
   },
   {
      id: '612',
      name: 'Banco Guanabara S.A.',
   },
   {
      id: '613',
      name: 'Omni Banco S.A.',
   },
   {
      id: '623',
      name: 'Banco PAN S.A.',
   },
   {
      id: '626',
      name: 'Banco Ficsa S.A.',
   },
   {
      id: '630',
      name: 'Banco Smartbank S.A.',
   },
   {
      id: '633',
      name: 'Banco Rendimento S.A.',
   },
   {
      id: '634',
      name: 'Banco Triângulo S.A.',
   },
   {
      id: '637',
      name: 'Banco Sofisa S.A.',
   },
   {
      id: '643',
      name: 'Banco Pine S.A.',
   },
   {
      id: '652',
      name: 'Itaú Unibanco Holding S.A.',
   },
   {
      id: '653',
      name: 'Banco Indusval S.A.',
   },
   {
      id: '654',
      name: 'Banco A.J.Renner S.A.',
   },
   {
      id: '655',
      name: 'Banco Votorantim S.A.',
   },
   {
      id: '707',
      name: 'Banco Daycoval S.A.',
   },
   {
      id: '712',
      name: 'Banco Ourinvest S.A.',
   },
   {
      id: '739',
      name: 'Banco Cetelem S.A.',
   },
   {
      id: '741',
      name: 'Banco Ribeirão Preto S.A.',
   },
   {
      id: '743',
      name: 'Banco Semear S.A.',
   },
   {
      id: '745',
      name: 'Banco Citibank S.A.',
   },
   {
      id: '746',
      name: 'Banco Modal S.A.',
   },
   {
      id: '747',
      name: 'Banco Rabobank International Brasil S.A.',
   },
   {
      id: '748',
      name: 'Banco Cooperativo Sicredi S.A.',
   },
   {
      id: '751',
      name: 'Scotiabank Brasil S.A. Banco Múltiplo',
   },
   {
      id: '752',
      name: 'Banco BNP Paribas Brasil S.A.',
   },
   {
      id: '753',
      name: 'Novo Banco Continental S.A. – Banco Múltiplo',
   },
   {
      id: '754',
      name: 'Banco Sistema S.A.',
   },
   {
      id: '755',
      name: 'Bank of America Merrill Lynch Banco Múltiplo S.A.',
   },
   {
      id: '756',
      name: 'Banco Cooperativo do Brasil S.A. – BANCOOB',
   },
   {
      id: '757',
      name: 'Banco KEB HANA do Brasil S.A.',
   },
];

export function getBankByCode(code: string) {
   return bankCodes.find((bank) => bank.id === code)?.name;
}
